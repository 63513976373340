import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';

import 'leaflet/dist/leaflet.css';

const PinModal = ({ isOpen, onClose, onSubmit, lat, lng }) => {
  const [annotation, setAnnotation] = useState('');

  const handleSubmit = () => {
    onSubmit({ lat, lng, annotation: annotation || 'No annotation provided' });
    setAnnotation('');
  };

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '10px',
          width: '300px',
          maxWidth: '90%'
        }}
      >
        <h4 style={{ color: 'black', marginBottom: '10px' }}>Leave a message for future readers</h4>
        <textarea
          placeholder="Add your note here"
          value={annotation}
          onChange={(e) => setAnnotation(e.target.value)}
          rows={4}
          style={{ width: '100%', marginBottom: '10px' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            onClick={onClose}
            style={{
              backgroundColor: '#C6C6C6FF',
              color: 'white',
              border: 'none',
              padding: '5px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            style={{
              backgroundColor: '#E50ADAFF',
              color: 'white',
              border: 'none',
              padding: '5px 10px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const MapComponent = () => {
  const [approvedPins, setApprovedPins] = useState([]);
  const [userPins, setUserPins] = useState([]);
  const [newPin, setNewPin] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetch('/spots.json')
      .then(response => response.json())
      .then(data => setApprovedPins(data))
      .catch(error => console.error('Error fetching pins:', error));
  }, []);

  const handleSubmitPin = (pinData) => {
    const submittedPin = {
      ...pinData,
      id: new Date().getTime(),
    };
    setUserPins([...userPins, submittedPin]);
    console.log('Submitted Pin:', submittedPin);
    discordMessage(`> User submission: \n\`\`\`js\n${JSON.stringify(submittedPin, null, 2)}\n\`\`\`\nhttps://www.google.com/maps/search/${submittedPin.lat},${submittedPin.lng}`);
    setNewPin(null);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setNewPin(null);
    setIsModalOpen(false);
  };

  function MapClickHandler() {
    const map = useMapEvents({
      click(e) {
        setNewPin({ lat: e.latlng.lat, lng: e.latlng.lng });
        setIsModalOpen(true);
      },
    });
    return null;
  }

  return (
    <div style={{ overflow: 'hidden', height: '100vh' }}>
      <MapContainer
        center={[44.2253, -76.4951]}
        zoom={16}
        style={{ height: '100%', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MapClickHandler />

        {/* Render approved pins */}
        {approvedPins.map((pin) => (
          <Marker
            key={pin.id}
            position={[pin.lat, pin.lng]}
            icon={
              new Icon({
                iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })
            }
          >
            <Popup>
              <div>
                <h3>Author&apos;s Note</h3>
                <p>{pin.annotation}</p>
              </div>
            </Popup>
          </Marker>
        ))}

        {/* Render user-submitted pins */}
        {userPins.map((pin) => (
          <Marker
            key={pin.id}
            position={[pin.lat, pin.lng]}
            opacity={0.7}
            icon={
              new Icon({
                iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })
            }
          >
            <Popup>
              <div>
                <h3>Submission Note</h3>
                <p>{pin.annotation}</p>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>

      {/* Pin Input Modal */}
      {newPin && (
        <PinModal
          isOpen={isModalOpen}
          onClose={handleCancel}
          onSubmit={handleSubmitPin}
          lat={newPin.lat}
          lng={newPin.lng}
        />
      )}

      {/* Footer UI remains the same as previous version */}
      <h1
        style={{
          position: 'absolute',
          bottom: '10px',
          left: '10px',
          backgroundColor: 'white',
          color: 'black',
          padding: '0px 5px',
          borderRadius: '5px',
          zIndex: 1000,
          fontFamily: '"Comic Sans MS", "Comic Sans", system-ui',
          border: '2px solid gray',
          fontSize: '1.3em',
        }}
      >
        <a
          href="https://instagram.com/elijahderyaw"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          pinmytip.com by Elijah
        </a>
      </h1>
      <h1
        style={{
          position: 'absolute',
          top: '5px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          zIndex: 1000,
          textAlign: 'center',
          lineHeight: '1em',
        }}
      >
        <span style={{ fontSize: '0.65em' }}>Theme:<br /></span>
        <span style={{ fontSize: '0.80em' }}>
          Best Hookup Spots on Campus 📌<br />
        </span>
      </h1>
    </div>
  );
};

function discordMessage(message) {
  var xhr = new XMLHttpRequest();
  xhr.open("POST", 'https://discord.com/api/webhooks/1312160162815148062/TfGYquNkYXyLvU_6hum0tN1cGl54_G9I70F32ZEEISLmiJyDismc1emFgRAx421eDrmG', true);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(JSON.stringify({
    'content': message,
    'username': 'AI',
  }));
}


export default MapComponent;